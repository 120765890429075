import { IoCloseOutline } from 'react-icons/io5';
import styled from 'styled-components';

export const TrendingModalContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const TrendingModalHeader = styled.div`
  padding: 24px;
  border-bottom: 1px solid var(--athens-gray);
  p {
    font-size: 20px;
    font-weight: 600;
    color: var(--rhino);
  }
`;

export const CloseIcon = styled(IoCloseOutline)`
  font-size: 24px;
  cursor: pointer;
`;

export const DropdownWrapper = styled.div`
  margin-bottom: 19px;
  .dropdown2-wrapper {
    width: 60%;
    height: 40px;
    border: 1px solid var(--athens-gray);
    border-radius: 4px;
    flex: none;

    span {
      border: none !important;
    }
    svg {
      color: var(--manatee);
    }
  }
`;

export const DropdownWrapperTitle = styled.p`
  color: var(--rhino);
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 4px;
`;

export const InnerContainer = styled.div`
  padding: 24px;
`;

export const TitleText = styled.div`
  margin-bottom: 19px;
  input {
    width: 60%;
    height: 40px;
    padding: 11px 16px;
    border: 1px solid var(--athens-gray);
    border-radius: 4px;
    color: var(--rhino);
    &:focus {
      outline: none;
    }
  }
  input::placeholder {
    color: var(--manatee);
  }
`;

export const ReactTagsWrapper = styled.div`
  input {
    height: auto;
  }

  .react-tags {
    cursor: text;
    position: relative;
    width: 100%;
    height: 182px;
    min-height: 48px;
    padding: 16px;
    border-radius: 4px;
    font-size: 14px;
    background: inherit;
    border: 1px solid var(--athens-gray);
    overflow: scroll;
    overflow-x: hidden;
  }

  .react-tags__selected {
    display: flex;
    flex-wrap: wrap;
  }

  .react-tags__selected-tag {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin-right: 12px;
    margin-bottom: 12px;
    padding: 2px 8px;
    background: var(--athens-gray);
    border-radius: 20px;
    border: none;
    font-size: inherit;
    line-height: inherit;
    span {
      font-size: 12px;
      font-weight: 600;
      color: var(--rhino);
      pointer-events: none;
    }
  }

  .react-tags__selected-tag:after {
    content: 'x';
    font-size: 16px;
    cursor: pointer;
    color: var(--tertiary-text);
    margin-left: 8px;
    margin-bottom: 3px;
  }

  .react-tags__search {
    display: inline-block;
    /* prevent autoresize overflowing the container */
    max-width: 100%;
    /* match tag layout */
    padding: 7px 2px;
    margin-bottom: 6px;
  }

  .react-tags__search-input {
    /* prevent autoresize overflowing the container */
    max-width: 100%;
    min-width: 156px;
    height: 100%;
    background-color: inherit;
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;
    font-size: inherit;
    line-height: inherit;
    &:focus {
      border: 0;
      box-shadow: none;
    }
    &::-ms-clear {
      display: none;
    }
    &::placeholder {
      color: var(--manatee);
    }
  }
`;

export const TagContainerBottomText = styled.p`
  margin-top: 4px;
  font-weight: 400;
  font-size: 12px;
  color: var(--rhino);
`;

export const TrendingFooter = styled.div`
  position: absolute;
  bottom: 0;
  padding: 24px 24px 24px 0;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  justify-content: flex-end;
  border-top: 1px solid var(--athens-gray);

  span {
    padding: 9px 24px;
    background: var(--watermelon);
    border-radius: 4px;
    height: 40px;
    text-align: center;
    color: var(--white);
    cursor: pointer;
  }
`;

export const TagContainer = styled.div`
  background-color: var(--chablis);
  padding: 2px 8px;
  border-radius: 12px;
  margin-right: 12px;
  margin-bottom: 12px;
  cursor: default;

  p {
    font-size: 12px;
    font-weight: 600;
    margin-right: 4px;
    color: var(--watermelon);
  }
`;

export const SvgButton = styled.button`
  all: unset;

  svg {
    cursor: pointer;
    color: var(--tertiary-text);
    font-size: 16px;
  }
`;

export const ThreeDotsLoaderContainer = styled.div`
  div {
    height: 68%;
    width: 100%;
    background-color: var(--white);
    margin-left: -20px;
    z-index: 1;
  }
`;
