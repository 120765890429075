export const CLOUDINARY_CLOUD_NAME =
  process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
export const CLOUDINARY_UPLOAD_PRESET =
  process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET;
export const DEVICE_ID = '3';

export const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const CATALOG_MANAGEMENT_SERVER_URL =
  process.env.REACT_APP_CATALOG_MANAGEMENT_SERVER_URL ||
  'https://catalogapidev.fashinza.com';

export const AWS_CREDENTIAL_SERVER_URL =
  process.env.REACT_APP_AWS_CREDENTIAL_SERVER_URL;

export const TOKEN = process.env.REACT_APP_TOKEN || String();

export const URL = process.env.REACT_APP_URL;

export const TOKEN_AUTH = process.env.REACT_APP_TOKEN_AUTH || '';
