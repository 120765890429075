import { MdClose } from 'react-icons/md';
import styled from 'styled-components';

export const TagsWrapper = styled.div`
  margin-top: 10px;
  cursor: default;
  div:first-child {
    margin-left: 0;
  }
`;

export const Tag = styled.div`
  border: 1px solid var(--athens-gray);
  border-radius: 52px;
  padding: 4px 12px;
  margin-left: 8px;
  vertical-align: middle;
  margin-bottom: 20px;
  line-height: 17px;
  background-color: var(--chablis);
`;

export const TagName = styled.p`
  display: inline;
  font-size: 12px;
  font-weight: 450;
  line-height: 12px;
  color: var(--watermelon);
  padding-right: 5px;
`;

export const Cross = styled(MdClose)`
  font-size: 11px;
  color: var(--rhino);
  cursor: pointer;
`;

export const ClearAllName = styled.p`
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  text-align: left;
  margin-bottom: 20px;
  margin-right: 4px;
  color: var(--deep-cerulean);
  cursor: pointer;
`;
