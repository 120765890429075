import styled, { keyframes } from 'styled-components';

export const ButtonGroupAbsolute = styled.div`
  right: 32px;
  top: -6px;
  min-height: 75px;
`;

export const TrendingModalNavigation = styled.div`
  height: 32px;
  width: 32px;
  border-radius: 24px;
  padding: 8px;

  &:hover {
    background-color: var(--athens-gray);
  }
`;

export const TrippleDotDropdown = styled.div`
  width: 240px;
  height: fit-content;
  top: 55px;
  right: 10px;
  z-index: 1;
  background-color: var(--white);
  border: 1px solid var(--athens-gray);
  border-radius: 4px;
  padding-top: 6px;
  box-shadow: 0 16px 32px rgba(44, 53, 86, 0.15);
`;

export const TrippleDotDropdownTitle = styled.div`
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: var(--rhino);
  padding: 8px 12px;
  margin-bottom: 8px;
  background-color: var(--white);

  &:hover {
    background-color: var(--input-disabled);
  }
`;

export const UploadSpinnerWrapper = styled.div`
  svg {
    margin: 0;
    height: 16px;
    width: 16px;

    circle.path {
      stroke: var(--white);
    }
  }
`;

const spin = keyframes`
    0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Spinner = styled.div`
  height: 28px;
  width: 28px;
  font-size: 4px;
  transform: translateZ(0);
  animation: ${spin} 1s linear infinite;
  border: 4px solid var(--white);
  border-left-color: var(--watermelon);
  border-radius: 50%;
`;
