import { ChangeEvent, useEffect } from 'react';
import { BsUpload } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import {
  fetchCatalogsSuccess,
  fetchCatalogsThunk,
  setPaginationInfo,
  setUploadModalVisbility,
  uploadUpdatedCatalogSheetThunk,
} from 'actions/catalog';
import { CATALOG_SHEET_FILE_TYPES } from 'components/AddCatalogue/constant';
import FileUpload from 'components/FileUpload';
import ICONOGRAPHY from 'CustomConstant/icons';
import Button from 'library/Button';
import Toast from 'library/Toast';
import { uploadFileAws } from 'services/aws';
import { useTypedSelector } from 'utils/hooks';
import { useBoolean } from 'utils/hooks/useBoolean';

import { Spinner } from './styles';

const { upload: UploadIcon } = ICONOGRAPHY;

const UploadSheet = () => {
  const dispatch = useDispatch();
  const [isUploadingFile, setIsUploadingFile] = useBoolean();
  const credentials = useTypedSelector(
    store => store.awsCredentials.catalogExcel
  );

  async function fileUploadHandler(event: ChangeEvent<HTMLInputElement>) {
    const { files } = event.target;
    if (files === null) {
      return;
    }
    setIsUploadingFile.on();
    const file = files[0];
    try {
      const response = await uploadFileAws({ file, credentials });
      //   setIsUploadingFile.off();
      // dispatch(setUploadedSheetUrl(response.url));
      updateSheetLink(response.url);
    } catch (error) {
      setIsUploadingFile.off();

      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }
    }
  }

  async function updateSheetLink(uploadedSheetUrl: string) {
    const data = {
      sheet_url: uploadedSheetUrl,
    };

    try {
      const [status, message]: any = await dispatch(
        uploadUpdatedCatalogSheetThunk(data)
      );

      setIsUploadingFile.off();
      if (status) {
        Toast.SUCCESS(message);
      } else {
        Toast.ERROR(message);
      }

      // setSearch(new URLSearchParams());
      dispatch(fetchCatalogsSuccess([]));
      dispatch(setPaginationInfo({ count: 0, totalPages: -1 }));
      dispatch(fetchCatalogsThunk(''));
      dispatch(setUploadModalVisbility(false));
    } catch (error) {
      if (error instanceof Error) {
        Toast.ERROR(error.message);
      }

      dispatch(setUploadModalVisbility(false));
    }
  }

  return (
    <Button variant="tertiary" size="medium" onClick={() => {}}>
      <FileUpload
        accept={CATALOG_SHEET_FILE_TYPES.join(',')}
        onChange={event => fileUploadHandler(event)}
        className="upload-excel-button"
      >
        <div className="center">
          {isUploadingFile ? (
            <Spinner />
          ) : (
            <UploadIcon stroke={'var(--watermelon)'} className="icon" />
          )}
          <div>&nbsp; Upload Updated Sheet</div>
        </div>
      </FileUpload>
    </Button>
  );
};

export default UploadSheet;
