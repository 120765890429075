import { ChangeEvent, useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactTags, { Tag } from 'react-tag-autocomplete';
import Dropdown from 'components/Dropdown';
import TagComponent from 'components/TrendingModal/TagComponent';
import DateInput from 'components/DateSelector';
import { useTypedSelector } from 'utils/hooks';
import { useBoolean } from 'utils/hooks/useBoolean';
import { getQueryParams } from 'components/AddCatalogList/helpers';
import { DEFAULT_VALUE } from 'utils/defaults';
import ICONOGRAPHY from 'CustomConstant/icons';
import { FilterSlugType } from 'components/AddCatalogList/types';
import CurrencyInput from './CurrencyInput';
import LocationFilter from './LocationFilter';
import ClickOutside from '../clickOutside/ClickOutside';
import FilterSearchBox from '../CreatedByFilter/FilterSearchBox';
import { getAttributeOptionsInTags } from './helper';
import { useAllDesignerDetail } from '../helper';
import * as Styles from './styles';

const { close: CloseIcon } = ICONOGRAPHY;

interface IMoreFilterViewProps {
  onClose(): void;
  onFilterChange(
    param: Partial<Record<FilterSlugType, Array<any> | string | number>>
  ): void;
}

const MoreFilterView = ({ onClose, onFilterChange }: IMoreFilterViewProps) => {
  const admins = useTypedSelector(state => state.catalog.admins);
  const addedBy =
    useTypedSelector(state => state.catalog.primaryFilters.added_by) ?? [];
  const isSupplier = useTypedSelector(state => state.user.is_supplier);
  const { search } = useLocation();
  const [isLocationFilterOpen, locationFilterAction] = useBoolean();
  const [isDesignerFilterOpen, designerFilterAction] = useBoolean();
  const currentQueryParams = getQueryParams(search);
  const initalProductIds = currentQueryParams.product_id
    ? getAttributeOptionsInTags(currentQueryParams.product_id)
    : [];
  const initialDesignLab = currentQueryParams.design_lab_code
    ? getAttributeOptionsInTags(currentQueryParams.design_lab_code)
    : [];
  const [tags, setTags] = useState<Array<Tag>>(initalProductIds);
  const [tagsDesignLab, setTagsDesignLab] =
    useState<Array<Tag>>(initialDesignLab);

  const [minPrice, setMinPriceAction] = useState(
    currentQueryParams.min_price && currentQueryParams.min_price[0]
  );
  const [maxPrice, setMaxPriceAction] = useState(
    currentQueryParams.max_price && currentQueryParams.max_price[0]
  );
  const [startDate, setStartDate] = useState(
    currentQueryParams.min_created_on_timestamp &&
      Number(currentQueryParams.min_created_on_timestamp[0])
  );
  const [endDate, setEndDate] = useState(
    currentQueryParams.max_created_on_timestamp &&
      Number(currentQueryParams.max_created_on_timestamp[0])
  );
  const [isCalendarOpen, calendarActions] = useBoolean();
  const [isEndCalendarOpen, endCalendarActions] = useBoolean();
  const [addedById, setAddedById] = useState(
    currentQueryParams.added_by && currentQueryParams.added_by[0]
  );
  const [selectedLocations, setSelectedLocations] = useState(
    currentQueryParams.region ? currentQueryParams.region : []
  );
  const [selectedDesigners, setSelectedDesigners] = useState(
    currentQueryParams.designer_id && currentQueryParams.designer_id[0]
  );
  const designersData = useAllDesignerDetail();

  function onTagAddition(tag: Tag) {
    let productIdsString = tag.name;

    if (productIdsString.startsWith(',')) {
      productIdsString = productIdsString.slice(1);
    }

    if (productIdsString.endsWith(',')) {
      productIdsString = productIdsString.slice(0, productIdsString.length - 1);
    }

    const newTags = productIdsString.split(',');
    const newCommaSeparatedTagsMap = new Map<string, Tag>();

    newTags
      .filter(tag => tag.trim() !== '')
      .forEach(eachTag => {
        const tagObj = {
          id: eachTag.trim(),
          name: eachTag.trim(),
        };
        newCommaSeparatedTagsMap.set(eachTag, tagObj);
      });
    setTags([...tags, ...Array.from(newCommaSeparatedTagsMap.values())]);
  }

  function onDesignLabTagAddition(tag: Tag) {
    let designLabString = tag.name;

    if (designLabString.startsWith(',')) {
      designLabString = designLabString.slice(1);
    }

    if (designLabString.endsWith(',')) {
      designLabString = designLabString.slice(0, designLabString.length - 1);
    }

    const newTags = designLabString.split(',');
    const newCommaSeparatedTagsMap = new Map<string, Tag>();

    newTags
      .filter(tag => tag.trim() !== '')
      .forEach(eachTag => {
        const tagObj = {
          id: eachTag.trim(),
          name: eachTag.trim(),
        };
        newCommaSeparatedTagsMap.set(eachTag, tagObj);
      });
    setTagsDesignLab([
      ...tagsDesignLab,
      ...Array.from(newCommaSeparatedTagsMap.values()),
    ]);
  }

  function onTagDeletion(tagIndex: number) {
    const tagToDelete = tags[tagIndex];
    const newTags = tags.filter(tag => tag !== tagToDelete);
    setTags(newTags);
  }

  function onDesignLabTagDeletion(tagIndex: number) {
    const tagToDelete = tagsDesignLab[tagIndex];
    const newTags = tagsDesignLab.filter(tag => tag !== tagToDelete);
    setTagsDesignLab(newTags);
  }

  function handleApplyFilter() {
    onFilterChange({
      max_price: maxPrice ? [maxPrice] : [],
      min_price: minPrice ? [minPrice] : [],
      min_created_on_timestamp: startDate ? [startDate] : [],
      max_created_on_timestamp: endDate ? [endDate] : [],
      product_id: tags.map(tag => tag.name),
      design_lab_code: tagsDesignLab.map(tag => tag.name),
      region: selectedLocations,
      designer_id: selectedDesigners ?? [],
      added_by: addedById ?? [],
    });
    onClose();
  }

  function handleClearOption() {
    onFilterChange({
      min_price: [],
      max_price: [],
      product_id: [],
      region: [],
      min_created_on_timestamp: [],
      max_created_on_timestamp: [],
    });
    setTags([]);
    setTagsDesignLab([]);
    setMinPriceAction([]);
    setMaxPriceAction([]);
    setStartDate([]);
    setEndDate([]);
    onClose();
  }

  const getAdminName = useCallback(
    (id: number) => {
      const admin = admins.find(eachAdmin => eachAdmin.id === id);
      return admin ? `${admin?.first_name} ${admin?.last_name}` : DEFAULT_VALUE;
    },
    [admins]
  );

  function handleStartDayClick(selectedDate: Date) {
    setStartDate(new Date(selectedDate).getTime());
    calendarActions.off();
  }

  function handleEndDayClick(selectedDate: Date) {
    setEndDate(new Date(selectedDate).getTime());
    endCalendarActions.off();
  }

  return (
    <Styles.MoreFilterContainer className="full-height">
      <div className="spread">
        <Styles.CategoryFilterHeaderText>
          Filters
        </Styles.CategoryFilterHeaderText>
        <Styles.CloseIcon className="cursor-pointer" onClick={onClose}>
          <CloseIcon />
        </Styles.CloseIcon>
      </div>
      <Styles.Back />
      <Styles.MoreFilterBody>
        <Styles.DateWrapper className="d-flex">
          <Styles.StartDateWrapper>
            <Styles.CurrencyCategory>Start Date</Styles.CurrencyCategory>
            <DateInput
              isOpen={isCalendarOpen}
              handleDayClick={handleStartDayClick}
              dateValue={startDate}
              setIsCalendarOpen={calendarActions}
              disabledAfter={new Date()}
            />
          </Styles.StartDateWrapper>
          <Styles.StartDateWrapper>
            <Styles.CurrencyCategory>End Date</Styles.CurrencyCategory>
            <DateInput
              isOpen={isEndCalendarOpen}
              handleDayClick={handleEndDayClick}
              dateValue={endDate}
              setIsCalendarOpen={endCalendarActions}
              disabledAfter={new Date()}
              isDisabled={startDate ? false : true}
              disabledBefore={startDate ?? new Date()}
            />
          </Styles.StartDateWrapper>
        </Styles.DateWrapper>
        <Styles.CurrencyCategory>Product Ids</Styles.CurrencyCategory>
        <Styles.ReactTagsWrappers>
          <ReactTags
            tags={tags}
            tagComponent={TagComponent}
            onAddition={onTagAddition}
            onDelete={onTagDeletion}
            addOnBlur
            allowNew
            placeholderText="Product Ids"
          />
        </Styles.ReactTagsWrappers>
        <Styles.CurrencyCategory>Design Lab Code</Styles.CurrencyCategory>
        <Styles.ReactTagsWrappers>
          <ReactTags
            tags={tagsDesignLab}
            tagComponent={TagComponent}
            onAddition={onDesignLabTagAddition}
            onDelete={onDesignLabTagDeletion}
            addOnBlur
            allowNew
            placeholderText="Design Lab Code"
          />
        </Styles.ReactTagsWrappers>
        <Styles.PriceFilterContainer>
          <div className="space-between full-width">
            <Styles.PriceTag>
              <CurrencyInput
                type="number"
                category="Minimum price"
                amount={minPrice}
                onChange={setMinPriceAction}
                showSymbol
                placeholder="Price"
              />
            </Styles.PriceTag>
            <Styles.PriceTag>
              <CurrencyInput
                type="number"
                placeholder="Price"
                category="Maximum price"
                amount={maxPrice}
                onChange={setMaxPriceAction}
                showSymbol
              />
            </Styles.PriceTag>
          </div>
          <div className="pos-r full-width" onClick={e => e.stopPropagation()}>
            <Styles.CurrencyCategory>Region</Styles.CurrencyCategory>
            <FilterSearchBox
              value={selectedLocations}
              isFilterSearchBarOpen={isLocationFilterOpen}
              toggleFilterSearchBox={() => locationFilterAction.toggle()}
              placeholder="Select"
            />
            {isLocationFilterOpen && (
              <ClickOutside
                onClose={() => locationFilterAction.off()}
                disableClickHandler={false}
              >
                <LocationFilter
                  selectedLocations={selectedLocations}
                  onChange={setSelectedLocations}
                />
              </ClickOutside>
            )}
          </div>
          <Styles.DropdownWrapperAddedBy className="full-width">
            <Styles.DropdownWrapperTitle>Designer</Styles.DropdownWrapperTitle>
            <Dropdown
              options={designersData}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setSelectedDesigners(event.target.value)
              }
              value={selectedDesigners}
              dataname="designer_id"
              datalabel="All"
              dropdownClass="dropdown-class"
              dropdownBoxClass="dropdownBox-class"
              dropdownItemClass="dropdown-item-class"
              openClass="openClass"
              itemClass="itemClass"
              isSearchBox
            />
          </Styles.DropdownWrapperAddedBy>
          {!isSupplier && (
            <Styles.DropdownWrapperAddedBy className="full-width">
              <Styles.DropdownWrapperTitle>
                Added by
              </Styles.DropdownWrapperTitle>
              <Dropdown
                options={addedBy.map(eachAdmin => ({
                  id: eachAdmin.value,
                  name: getAdminName(eachAdmin.value),
                }))}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setAddedById(event.target.value)
                }
                value={addedById}
                dataname="added_by"
                datalabel="All"
                dropdownClass="dropdown-class"
                dropdownBoxClass="dropdownBox-class"
                dropdownItemClass="dropdown-item-class"
                openClass="openClass"
                itemClass="itemClass"
                isSearchBox
              />
            </Styles.DropdownWrapperAddedBy>
          )}
          <Styles.BottomTabButtons className="pos-a flex-end">
            <Styles.ClearButton onClick={handleClearOption}>
              Clear Filter
            </Styles.ClearButton>
            <Styles.ApplyButton
              className="cursor-pointer"
              onClick={handleApplyFilter}
            >
              Apply
            </Styles.ApplyButton>
          </Styles.BottomTabButtons>
        </Styles.PriceFilterContainer>
      </Styles.MoreFilterBody>
    </Styles.MoreFilterContainer>
  );
};

export default MoreFilterView;
