import React, { ChangeEvent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toggleTrendingModal } from 'actions/catalog';
import { fetchAWSCredentialsThunk } from 'actions/credential';
import Emailers from 'components/Emailers';
import ICONOGRAPHY from 'CustomConstant/icons';
import SideDrawer from 'library/SideDrawer';
import { useTypedSelector } from 'utils/hooks';
import { useBoolean } from 'utils/hooks/useBoolean';
import UploadSheet from './UploadSheet';

import * as Styled from './styles';

const { 'vertical-dots': VerticalDotsIcon } = ICONOGRAPHY;

const ButtonGroupAbsolute = () => {
  const [showEmailers, setShowEmailers] = useBoolean();
  // const uploadModalVisibility = useTypedSelector(
  //   state => state.catalog.uploadModalVisibility
  // );
  const isAdmin = useTypedSelector(state => state.user.is_admin);

  const dispatch = useDispatch();
  const [isTripleDotDropdownOpen, setTripleDotDropdownActions] = useBoolean();

  useEffect(() => {
    dispatch(fetchAWSCredentialsThunk());
  }, []);

  // function toggleModalVisibility() {
  //   dispatch(setUploadModalVisbility(!uploadModalVisibility));
  // }

  function handleTrendingModal(isOpen: boolean) {
    dispatch(toggleTrendingModal(isOpen));
  }

  return (
    <>
      <Styled.ButtonGroupAbsolute className="pos-a end-align-center">
        <UploadSheet />
        {isAdmin && (
          <Styled.TrendingModalNavigation
            className="cursor-pointer"
            onClick={() => setTripleDotDropdownActions.toggle()}
          >
            <VerticalDotsIcon
              size={16}
              strokeWidth={1.75}
              stroke="var(--tertiary-text)"
            />
          </Styled.TrendingModalNavigation>
        )}
        {isTripleDotDropdownOpen && (
          <>
            <Styled.TrippleDotDropdown className="pos-a">
              <Styled.TrippleDotDropdownTitle
                className="full-dimension align-center cursor-pointer"
                onClick={() => {
                  handleTrendingModal(true);
                  setTripleDotDropdownActions.off();
                }}
              >
                Add trending products
              </Styled.TrippleDotDropdownTitle>
              <Styled.TrippleDotDropdownTitle
                className="full-dimension align-center cursor-pointer"
                onClick={() => {
                  setShowEmailers.on();
                  setTripleDotDropdownActions.off();
                }}
              >
                Emailers
              </Styled.TrippleDotDropdownTitle>
            </Styled.TrippleDotDropdown>
          </>
        )}
        <SideDrawer isOpen={showEmailers} width="calc(100vw - 2px)">
          {showEmailers && <Emailers onClose={() => setShowEmailers.off()} />}
        </SideDrawer>
      </Styled.ButtonGroupAbsolute>
    </>
  );
};

export default ButtonGroupAbsolute;
