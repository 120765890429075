import { useState } from 'react';
import { Column } from 'react-table';
import { MdContentCopy } from 'react-icons/md';
import Tooltip from 'react-tooltip-lite';
import ImageWithTooltip from 'library/ImageWithTooltip';
import Portal from 'components/Common/Portal/Portal';
import { useAllDesignerDetail } from 'components/Filters/helper';
import { useTypedSelector } from 'utils/hooks';
import { useBoolean } from 'utils/hooks/useBoolean';
import { copyToClipboard } from 'utils/utility';
import { customFormatDate, modifyImageURl } from 'utils/utility/utils';
import {
  convertSizeJsonToString,
  getRandomColorForImageBg,
} from 'utils/helpers';
import { DEFAULT_PRODUCT_IMAGE, DEFAULT_VALUE } from 'utils/defaults';
import { IAttribute, IImage, IMaterial } from 'utils/types';
import ImageUpdation from './ImageUpdation';
import { redirectToOrder, redirectToProduct } from './helpers';
import { DEFAULT_EMPTY_VIEW, StatusEnum } from './constants';
import { IGetLocationData } from './types';
import * as Styled from './styles';
import { ObjectType } from 'customTypes/defined';

type ColumnType = Column & {
  sticky?: 'left' | 'right';
};

export function getFields(): Array<ColumnType> {
  return [
    {
      Header: <Styled.CellHeaderWrapper>Image</Styled.CellHeaderWrapper>,
      accessor: 'images',
      sticky: 'left',
      width: 80,
      Cell: ({ row, value }) => {
        const { id } = row.original as {
          id: number;
        };
        const [isImageBroken, setIsImageBroken] = useState(
          value ? false : true
        );

        const countToDisplay = value?.length - 1;
        const [isImageUploadOpen, imageUploadOpenAction] = useBoolean();

        function onError() {
          setIsImageBroken(true);
        }

        function handleImageChange() {
          imageUploadOpenAction.toggle();
        }

        const mainImage: IImage = value?.find(
          (image: IImage) => image.view === 'default'
        );

        const compressedImage =
          mainImage?.url?.length > 1
            ? modifyImageURl(
                isImageBroken ? DEFAULT_PRODUCT_IMAGE : mainImage?.url
              )
            : '';

        return (
          <>
            {isImageUploadOpen && (
              <Portal>
                <ImageUpdation
                  rowId={id}
                  existingImage={!isImageBroken ? value : [DEFAULT_EMPTY_VIEW]}
                  onClose={() => imageUploadOpenAction.off()}
                />
              </Portal>
            )}
            <Styled.CellBodyWrapper as="div" isVertical hideBorder>
              <ImageWithTooltip imageSrc={compressedImage} altText="Product">
                <Styled.ImageWrapper className="cursor-pointer">
                  <img
                    alt="Product"
                    src={compressedImage ?? DEFAULT_PRODUCT_IMAGE}
                    onError={onError}
                    onClick={handleImageChange}
                  />
                  {countToDisplay > 0 && <span>+{countToDisplay}</span>}
                </Styled.ImageWrapper>
              </ImageWithTooltip>
            </Styled.CellBodyWrapper>
          </>
        );
      },
    },
    {
      Header: <Styled.CellHeaderWrapper>Name</Styled.CellHeaderWrapper>,
      accessor: 'product_name',
      sticky: 'left',
      Cell: ({ row, value }) => {
        const { is_approved, is_active, design_lab_code, product_id } =
          row.original as {
            is_approved: boolean;
            is_active: boolean;
            design_lab_code: string;
            product_id: string;
          };
        const isAllowRedirection = is_active && is_approved;
        return (
          <Styled.CellBodyWrapper className="v-d-flex">
            <Styled.ProductIdTitleWrapper className=" align-center">
              <Tooltip
                background="var(--white)"
                color="var(--rhino)"
                direction="bottom"
                content={value}
                className="download-button-tooltip truncated-text"
              >
                {value || DEFAULT_VALUE}
              </Tooltip>
            </Styled.ProductIdTitleWrapper>
            <br />

            <Styled.ProductIdTitleWrapper className="space-between align-center">
              <Tooltip
                background="var(--white)"
                color="var(--rhino)"
                direction="bottom"
                content={product_id}
                className="download-button-tooltip truncated-text"
              >
                <Styled.ProductIdTitle
                  as="a"
                  href={
                    isAllowRedirection ? redirectToProduct(product_id) : '#'
                  }
                  onClick={e => {
                    if (!isAllowRedirection) {
                      e.preventDefault();
                      e.stopPropagation();
                    }
                  }}
                  target="_blank"
                  className="cursor-pointer truncated-text"
                  style={{ fontSize: 12.5 }}
                  isDisabled={!isAllowRedirection}
                >
                  {product_id || DEFAULT_VALUE}
                </Styled.ProductIdTitle>
              </Tooltip>
              <div>
                <MdContentCopy
                  className="cursor-pointer"
                  size="14px"
                  fill="var(--tertiary-text)"
                  onClick={() => copyToClipboard(product_id)}
                />
              </div>
            </Styled.ProductIdTitleWrapper>

            <Styled.ProductIdTitleWrapper className="space-between align-center">
              <Tooltip
                background="var(--white)"
                color="var(--rhino)"
                direction="bottom"
                content={design_lab_code || DEFAULT_VALUE}
                className="download-button-tooltip truncated-text"
              >
                <Styled.ProductIdTitle
                  as="a"
                  href={
                    isAllowRedirection ? redirectToProduct(product_id) : '#'
                  }
                  onClick={e => {
                    if (!isAllowRedirection) {
                      e.preventDefault();
                      e.stopPropagation();
                    }
                  }}
                  target="_blank"
                  className="cursor-pointer truncated-text"
                  isDisabled={!isAllowRedirection}
                >
                  {design_lab_code || DEFAULT_VALUE}
                </Styled.ProductIdTitle>
              </Tooltip>
              <MdContentCopy
                className="cursor-pointer"
                size="14px"
                fill="var(--tertiary-text)"
                onClick={() => copyToClipboard(design_lab_code)}
              />
            </Styled.ProductIdTitleWrapper>
          </Styled.CellBodyWrapper>
        );
      },
    },
    {
      Header: <Styled.CellHeaderWrapper>Category</Styled.CellHeaderWrapper>,
      accessor: 'category',
      sticky: 'left',
      Cell: ({ value }) => {
        let category_name = value.name;
        return (
          <Styled.CellBodyWrapper>
            {category_name || DEFAULT_VALUE}
          </Styled.CellBodyWrapper>
        );
      },
    },
    {
      Header: <Styled.CellHeaderWrapper>Added By</Styled.CellHeaderWrapper>,
      accessor: 'added_by',
      Cell: ({ row, value }) => {
        const { designer_id } = row.original as {
          designer_id: number;
        };

        const designersData = useAllDesignerDetail();
        const designerName = designersData.find(
          each => Number(each.id) === designer_id
        )?.name;
        const admins = useTypedSelector(state => state.catalog.admins);
        const admin = admins.find(eachAdmin => eachAdmin.id === value);

        return (
          <Styled.CellBodyWrapper className="v-d-flex">
            <Styled.ClusterText>
              {`${admin?.first_name || ''} ${admin?.last_name || ''}` ||
                DEFAULT_VALUE}
            </Styled.ClusterText>
            <Styled.ClusterText>
              Designer: {designerName || designer_id || DEFAULT_VALUE}
            </Styled.ClusterText>
          </Styled.CellBodyWrapper>
        );
      },
    },
    {
      Header: <Styled.CellHeaderWrapper>Price</Styled.CellHeaderWrapper>,
      accessor: 'price',
      Cell: ({ value }) => (
        <Styled.CellBodyWrapper>
          {value || DEFAULT_VALUE}
        </Styled.CellBodyWrapper>
      ),
    },
    {
      Header: <Styled.CellHeaderWrapper>GSM</Styled.CellHeaderWrapper>,
      accessor: 'gsm',
      Cell: ({ value }) => (
        <Styled.CellBodyWrapper>
          {value || DEFAULT_VALUE}
        </Styled.CellBodyWrapper>
      ),
    },
    {
      Header: <Styled.CellHeaderWrapper>Material</Styled.CellHeaderWrapper>,
      accessor: 'material',
      Cell: ({ value }) => {
        const materials: Array<string> = [];
        value?.forEach((material: IMaterial) => {
          materials.push(`${material.name} : ${material.composition}%`);
        });

        return (
          <Styled.CellBodyWrapper>
            {
              <ul>
                {materials.map(material => (
                  <li key={material}>{material}</li>
                ))}
              </ul>
            }
          </Styled.CellBodyWrapper>
        );
      },
    },
    {
      Header: <Styled.CellHeaderWrapper>Region</Styled.CellHeaderWrapper>,
      accessor: 'region',
      Cell: ({ value }) => {
        const regions: Array<string> = [];
        value?.forEach((region: IGetLocationData) => {
          regions.push(region.name);
        });
        return (
          <Styled.CellBodyWrapper>
            {regions.length === 0 ? (
              DEFAULT_VALUE
            ) : (
              <ul>
                {regions.map(region => (
                  <li key={region}>{region}</li>
                ))}
              </ul>
            )}
          </Styled.CellBodyWrapper>
        );
      },
    },
    {
      Header: <Styled.CellHeaderWrapper>Colour map</Styled.CellHeaderWrapper>,
      accessor: 'colour_map',
      Cell: ({ value }) => (
        <Styled.CellBodyWrapper>
          {value || DEFAULT_VALUE}
        </Styled.CellBodyWrapper>
      ),
    },
    {
      Header: <Styled.CellHeaderWrapper>Colour name</Styled.CellHeaderWrapper>,
      accessor: 'colour_name',
      Cell: ({ value }) => (
        <Styled.CellBodyWrapper>
          {value || DEFAULT_VALUE}
        </Styled.CellBodyWrapper>
      ),
    },
    {
      Header: <Styled.CellHeaderWrapper>Gender</Styled.CellHeaderWrapper>,
      accessor: 'gender',
      Cell: ({ value }) => (
        <Styled.CellBodyWrapper>
          {value || DEFAULT_VALUE}
        </Styled.CellBodyWrapper>
      ),
    },
    {
      Header: <Styled.CellHeaderWrapper>Description</Styled.CellHeaderWrapper>,
      accessor: 'product_description',
      Cell: ({ value }) => (
        <Styled.CellBodyWrapper>
          {value || DEFAULT_VALUE}
        </Styled.CellBodyWrapper>
      ),
    },
    {
      Header: <Styled.CellHeaderWrapper>Key Features</Styled.CellHeaderWrapper>,
      accessor: 'key_features',
      Cell: ({ value = [] }) => (
        <Styled.CellBodyWrapper>{value.join(', ')}</Styled.CellBodyWrapper>
      ),
    },
    {
      Header: (
        <Styled.CellHeaderWrapper>Supplier name</Styled.CellHeaderWrapper>
      ),
      accessor: 'supplier_id',
      Cell: ({ value }) => {
        const supplierList = useTypedSelector(
          store => store.catalog.filters.supplier
        );
        const supplierName = supplierList.filter(
          supplier => supplier.id === value
        )[0]?.name;

        return (
          <Styled.CellBodyWrapper>
            {supplierName || DEFAULT_VALUE}
          </Styled.CellBodyWrapper>
        );
      },
    },
    {
      Header: <Styled.CellHeaderWrapper>Design Type</Styled.CellHeaderWrapper>,
      accessor: 'design_type',
      Cell: ({ value }) => (
        <Styled.CellBodyWrapper>
          {value || DEFAULT_VALUE}
        </Styled.CellBodyWrapper>
      ),
    },
    {
      Header: (
        <Styled.CellHeaderWrapper>Design Source</Styled.CellHeaderWrapper>
      ),
      accessor: 'design_source',
      Cell: ({ value }) => (
        <Styled.CellBodyWrapper>
          {value || DEFAULT_VALUE}
        </Styled.CellBodyWrapper>
      ),
    },
    {
      Header: (
        <Styled.CellHeaderWrapper>Supplier Sku Id</Styled.CellHeaderWrapper>
      ),
      accessor: 'seller_sku_id',
      Cell: ({ value }) => (
        <Styled.CellBodyWrapper>
          {value || DEFAULT_VALUE}
        </Styled.CellBodyWrapper>
      ),
    },
    {
      Header: <Styled.CellHeaderWrapper>Cluster</Styled.CellHeaderWrapper>,
      accessor: 'cluster',
      Cell: ({ value }) => (
        <Styled.CellBodyWrapper className="v-d-flex">
          <Styled.ClusterText>
            Fabric : {value['fabric'] ?? DEFAULT_VALUE}{' '}
          </Styled.ClusterText>
          <Styled.ClusterText>
            AI Print : {value['ai_digital_print'] ?? DEFAULT_VALUE}
          </Styled.ClusterText>
          <Styled.ClusterText>
            Silhouette : {value['silhouette'] ?? DEFAULT_VALUE}
          </Styled.ClusterText>
        </Styled.CellBodyWrapper>
      ),
    },
    {
      Header: <Styled.CellHeaderWrapper>Orders</Styled.CellHeaderWrapper>,
      accessor: 'orders',
      Cell: ({ value }) => (
        <Styled.CellBodyWrapper className="v-d-flex">
          {value.map((each: string | number) => {
            if (each === 'N/A') return <p>{each}</p>;
            return (
              <Styled.ProductIdTitle
                key={each}
                as="a"
                href={redirectToOrder(each)}
                target="_blank"
                className="cursor-pointer"
              >
                {each}
              </Styled.ProductIdTitle>
            );
          })}
        </Styled.CellBodyWrapper>
      ),
    },

    {
      Header: (
        <Styled.CellHeaderWrapper>Data Quality Score</Styled.CellHeaderWrapper>
      ),
      accessor: 'score',
      Cell: ({ value }) => (
        <Styled.CellBodyWrapper>
          {value || DEFAULT_VALUE}
        </Styled.CellBodyWrapper>
      ),
    },

    {
      Header: <Styled.CellHeaderWrapper>Is Approved</Styled.CellHeaderWrapper>,
      accessor: 'is_approved',
      Cell: ({ value }) => {
        let status: StatusEnum = value
          ? StatusEnum.APPROVED
          : StatusEnum.NOT_APPROVED;
        return (
          <Styled.CellBodyWrapper>
            <Styled.StatusIndicator status={status}>
              {value ? 'Approved' : 'Not Approved'}
            </Styled.StatusIndicator>
          </Styled.CellBodyWrapper>
        );
      },
    },

    {
      Header: <Styled.CellHeaderWrapper>Is Active</Styled.CellHeaderWrapper>,
      accessor: 'is_active',
      Cell: ({ value }) => {
        let status: StatusEnum = value
          ? StatusEnum.ACTIVE
          : StatusEnum.INACTIVE;
        return (
          <Styled.CellBodyWrapper>
            <Styled.StatusIndicator status={status}>
              {status}
            </Styled.StatusIndicator>
          </Styled.CellBodyWrapper>
        );
      },
    },

    {
      Header: (
        <Styled.CellHeaderWrapper>Fabric Construction</Styled.CellHeaderWrapper>
      ),
      accessor: 'fabric_construction',
      Cell: ({ value }) => (
        <Styled.CellBodyWrapper>
          {value || DEFAULT_VALUE}
        </Styled.CellBodyWrapper>
      ),
    },

    {
      Header: <Styled.CellHeaderWrapper>Pantone Code</Styled.CellHeaderWrapper>,
      accessor: 'pantone_code',
      Cell: ({ value }) => (
        <Styled.CellBodyWrapper>
          {value || DEFAULT_VALUE}
        </Styled.CellBodyWrapper>
      ),
    },
    {
      Header: <Styled.CellHeaderWrapper>Size</Styled.CellHeaderWrapper>,
      accessor: 'size_format',
      Cell: ({ value }) => (
        <Styled.CellBodyWrapper>
          {value && convertSizeJsonToString(value[0])}
        </Styled.CellBodyWrapper>
      ),
    },
    {
      Header: <Styled.CellHeaderWrapper>Attributes</Styled.CellHeaderWrapper>,
      accessor: 'attribute_list',
      width: 175,
      Cell: ({ value }) => {
        const attributes: Array<string> = [];
        value?.forEach((attribute: IAttribute) => {
          if (Array.isArray(attribute.attr_value)) {
            let attributeValue = '';
            attribute.attr_value.map((item, index) => {
              attributeValue = `${attributeValue}${index === 0 ? '' : ','} ${
                item.name
              } (${item.composition}%)`;
            });
            attributes.push(`${attribute.attr_name} : ${attributeValue}`);
          } else {
            attributes.push(`${attribute.attr_name} : ${attribute.attr_value}`);
          }
        });

        return (
          <Styled.CellBodyWrapper>
            <ul>
              {attributes.map(attribute => (
                <li key={attribute}>{attribute}</li>
              ))}
            </ul>
          </Styled.CellBodyWrapper>
        );
      },
    },
    {
      Header: (
        <Styled.CellHeaderWrapper>Available quantity</Styled.CellHeaderWrapper>
      ),
      accessor: 'available_qty',
      Cell: ({ value }) => (
        <Styled.CellBodyWrapper>
          {value || DEFAULT_VALUE}
        </Styled.CellBodyWrapper>
      ),
    },

    {
      Header: <Styled.CellHeaderWrapper>Created on</Styled.CellHeaderWrapper>,
      accessor: 'created_on_timestamp',
      Cell: ({ value }) => (
        <Styled.CellBodyWrapper>
          {customFormatDate(new Date(value)) || DEFAULT_VALUE}
        </Styled.CellBodyWrapper>
      ),
    },

    // {
    //   Header: <Styled.CellHeaderWrapper>Designer</Styled.CellHeaderWrapper>,
    //   accessor: 'designer_id',
    //   sticky: 'left',
    //   Cell: ({ value }) => {
    //     console.log('designer_id----value----', value);
    //     const designersData = useAllDesignerDetail();
    //     const designerName = designersData.find(
    //       each => Number(each.id) === value
    //     )?.name;
    //     return (
    //       <Styled.CellBodyWrapper>
    //         {designerName || DEFAULT_VALUE}
    //       </Styled.CellBodyWrapper>
    //     );
    //   },
    // },

    // {
    //   Header: <Styled.CellHeaderWrapper>MOQ</Styled.CellHeaderWrapper>,
    //   accessor: 'moq',
    //   Cell: ({ value }) => (
    //     <Styled.CellBodyWrapper>
    //       {value || DEFAULT_VALUE}
    //     </Styled.CellBodyWrapper>
    //   ),
    // },
  ];
}
