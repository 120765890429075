import {
  ISendLocationData,
  ISendQrCode,
} from 'components/AddCatalogList/types';
import {
  IDownloadPptType,
  IUplaodImageType,
} from 'components/AddCatalogList/ImageUpdation/types';
import { ISentFeedbackData } from 'components/RuleEngineCollection/types';
import { DesignLibraryType } from 'components/DesignLibrary/types';
import { IEmailers } from 'components/Emailers/types';
import { IObjectType } from '../types';
import { callAPI, callAPIRoot, methodOptions } from './api';

// get aws credentials
const auth = '/auth'; // auth
export const getCredentialsApi = () =>
  callAPI(methodOptions.GET, `/credentials/`);

export const verifyExistingTokenApi = (data = {}) =>
  callAPIRoot(methodOptions.POST, `${auth}/verifyexistingtoken/`, data);

export const getCatalogsApi = () => callAPI(methodOptions.GET, '/products/');

export const getSuppliersApi = () =>
  callAPIRoot(methodOptions.GET, '/catalogue/filters/');

export const getBrandsApi = () => {
  return callAPIRoot(methodOptions.GET, '/rfq/filters/');
};

export const getMaterialsApi = () => callAPI(methodOptions.GET, '/materials');

export const bulkProductStatusUpdateApi = (data: {} | undefined) =>
  callAPI(methodOptions.PUT, '/product/update_many/', data);

// catalogue Excel template

export const getExcelTemplateCategoriesApi = (categoryId: number | string) =>
  callAPI(methodOptions.GET, `/product/categories/${categoryId}/`);

export const getExcelTemplateDownloadLinkApi = (categoryId: number | string) =>
  callAPI(methodOptions.GET, `/product/${categoryId}/download_excel/`);

export const getDefaultExcelTemplateApi = () =>
  callAPI(methodOptions.GET, `/product/default_template/download_excel/`);

// upload catalogue excel
export const uploadCatalogueSheetLinkApi = (
  data: IObjectType,
  queryParams = ''
) => callAPI(methodOptions.POST, `/product/upload_excel/?${queryParams}`, data);

export const uploadDefaultCatalogueSheetLinkApi = (
  data: IObjectType,
  queryParams = ''
) =>
  callAPI(
    methodOptions.POST,
    `/product/default_template/upload_excel/?${queryParams}`,
    data
  );

// Generate Image link
export const getUploadedImagesApi = (
  supplierId: number | string,
  pageNumber: string | number
) =>
  callAPI(
    methodOptions.GET,
    `/product/${supplierId}/images/?page=${pageNumber}`
  );

export const updateUploadedImagesApi = ({ supplierId, data }: IObjectType) =>
  callAPI(methodOptions.POST, `/product/${supplierId}/upload_image/`, data);

export const getAdminCatalogsApi = (
  searchParams: string,
  page: number,
  pageSize: number
) => {
  return callAPI(
    methodOptions.GET,
    `/admin/products/?page_size=${pageSize}&page=${page}&${searchParams}`
  );
};

export const getAdminCatalogProductsApi = (
  searchParams: string,
  page: number,
  catalogId: string
) => {
  return callAPI(
    methodOptions.GET,
    `/admin/products/${catalogId}/?&page=${page}${
      searchParams ? `&${searchParams}` : ''
    }`
  );
};

export const getAllAdminCatalogProductsApi = (
  searchParams: string,
  pageSize: number,
  catalogId: string
) => {
  return callAPI(
    methodOptions.GET,
    `/admin/products/${catalogId}/?page_size=${pageSize}&excel=${true}${
      searchParams ? `&${searchParams}` : ''
    }`
  );
};

export const getAllCategoriesApi = () => {
  return callAPI(methodOptions.GET, `/product/categories/all/`);
};

export const bulkEditExcelDownloadApi = (data: any) => {
  return callAPI(
    methodOptions.POST,
    // `/product/download_excel_bulk_update/`,
    '/product/default_template/excel_bulk_update/',
    data
  );
};

export const uploadUpdatedCatalogSheetApi = (data: any) => {
  return callAPI(
    // methodOptions.POST,
    // `/product/upload_excel_bulk_update/`,
    methodOptions.PUT,
    '/product/default_template/excel_bulk_update/',
    data
  );
};

export const getTrendingProductsForCategoryApi = (categoryId: string) => {
  return callAPI(methodOptions.GET, `/banner/${categoryId}/`);
};

export const putTrendingProductsForCategoryDataApi = (
  data: any,
  categoryId: string
) => {
  return callAPI(methodOptions.PUT, `/banner/${categoryId}/`, data);
};

export const generateShareableLink = (data: any) =>
  callAPI(methodOptions.POST, `/product/share/`, data);

export const getAllSharedCatalogsApi = (pageNumber: number) =>
  callAPI(methodOptions.GET, `/product/share/?page=${pageNumber}`);

export const updateSharedCatalogDetails = (data: any, linkIdentifier: string) =>
  callAPI(methodOptions.PUT, `/product/share/${linkIdentifier}/`, data);

export const updateSharedCatalogProducts = (
  data: any,
  linkIdentifier: string
) =>
  callAPI(
    methodOptions.PUT,
    `/product/share/shared_catalog_products/${linkIdentifier}/`,
    data
  );

export const createCollectionFromSharedCatalogApi = (
  data: Record<string, any>
) => callAPI(methodOptions.POST, '/collections/', data);

export const getAllCollectionsApi = (page: number, filterParams: string) => {
  return callAPI(
    methodOptions.GET,
    `/product/collections/list?page=${page}${
      filterParams ? `&${filterParams}` : ''
    }`
  );
};

export const deleteCollectionApi = (data: Record<string, any>) => {
  return callAPI(methodOptions.DELETE, '/product/collections/list/', data);
};

export const updateCollectionApi = (data: Record<string, any>) =>
  callAPI(methodOptions.PUT, '/product/collections/list/', data);

export const getAllAdminsApi = () =>
  callAPIRoot(methodOptions.GET, '/auth/admin/users/?pagination=false');

export const getCmAndSaleRepDataApi = () =>
  callAPIRoot(
    methodOptions.GET,
    '/auth/team/users/list/?team=Category%20Manager%2CBD%20Head%2CBD%20Manager%2CBD%20Associate%2CDesigner%2CBU%20Head'
  );

// Rule based Collectons API
export const getAddRuleDataApi = () => {
  return callAPI(methodOptions.GET, '/cms/conditions_sorting_json/');
};

export const getAllRuleCollectionApi = (page: number, filterParams: string) => {
  return callAPI(
    methodOptions.GET,
    `/cms/rule_based_collection/?page=${page}${
      filterParams ? `&${filterParams}` : ''
    }`
  );
};

export const updateRuleCollectionApi = (data: any) =>
  callAPI(methodOptions.PUT, '/cms/rule_based_collection/', data);

export const createRuleCollectionApi = (data: Record<string, any>) =>
  callAPI(methodOptions.POST, '/cms/rule_based_collection/', data);

export const getLocationRegionDataApi = () => {
  return callAPI(methodOptions.GET, '/region/');
};

export const updateLocationRegionDataApi = (data: ISendLocationData) => {
  return callAPI(methodOptions.PUT, '/product/region/', data);
};

export const getCollectionFeedbackFilledApi = () => {
  return callAPI(methodOptions.GET, '/es/configs/collection_feedback/');
};

export const sendEachCollectionFeedbackApi = (data: ISentFeedbackData) => {
  return callAPI(methodOptions.POST, '/product/collection/feedback/', data);
};

export const getEachCollectionFeedbackApi = (eachRuleId: string) => {
  return callAPI(
    methodOptions.GET,
    `/product/collection/feedback/?id=${eachRuleId}`
  );
};

export const updateEachCollectionFeedbackApi = (data: ISentFeedbackData) => {
  return callAPI(methodOptions.PUT, '/product/collection/feedback/', data);
};

export const getQrCodeApi = (data: ISendQrCode) => {
  return callAPI(methodOptions.POST, '/product/download_pdf_qrcode/', data);
};

export const updateImagesApi = (data: IUplaodImageType) => {
  return callAPI(methodOptions.PUT, '/product/images/update/', data);
};

export const postProductsFabricClustersApi = (data: {
  product_ids: Array<string>;
  collection_ids: Array<string>;
}) => {
  return callAPI(methodOptions.PUT, '/product_fabric_cluster/', data);
};

export const generateOfflinePPT = (data: IDownloadPptType) => {
  return callAPI(methodOptions.POST, '/generate_ppt/', data);
};

export const uploadImageToS3 = (data: {
  cloudinary_url: string;
  scales: Array<string>;
  s3_prefix: string;
  s3_bucket_name: string;
}) => {
  return callAPI(methodOptions.POST, '/compress_image/', data);
};

//DESIGN_LIBRARY
export const getAllDesignsLibraryApi = (page: number, filterParams: string) => {
  return callAPI(
    methodOptions.GET,
    `/design-library/?page=${page}${filterParams ? `&${filterParams}` : ''}`
  );
};

export const getCollectionClusterApi = () =>
  callAPI(methodOptions.GET, '/product/collection/cluster/');

export const getSeasonAndFabricTypeApi = () =>
  callAPI(methodOptions.GET, '/es/configs/design-library-config');

export const addDesignLibraryDataApi = (data: DesignLibraryType) => {
  return callAPI(methodOptions.POST, '/design-library/', data);
};

export const removeDesignLibraryItemApi = (data: Record<string, any>) =>
  callAPI(methodOptions.DELETE, '/design-library/', data);

export const updateDesignLibraryItemApi = (data: DesignLibraryType) =>
  callAPI(methodOptions.PUT, '/design-library/', data);

//EMAILER

export const getEmailerDataApi = () =>
  callAPI(methodOptions.GET, '/es/configs/trends-mail-config/');

export const updateEmailerDataApi = (data: IEmailers) =>
  callAPI(methodOptions.POST, '/email/config/', data);

//Trends
export const getTagsApi = (filterParams: string) =>
  callAPI(
    methodOptions.GET,
    `/trends/tag/?${filterParams ? `&${filterParams}` : ''}`
  );

export const getRetailersApi = () =>
  callAPI(methodOptions.GET, '/trends/tag_brands/');

export const getChartTagsApi = (
  retailer: string,
  category: string,
  region: string,
  gender: string,
  date: string
) =>
  callAPI(
    methodOptions.GET,
    `/trends/tag_attributes/${retailer}/${category}/${gender}/${region}/${date}/`
  );

export const getChartApi = (endpoint: string) =>
  callAPI(methodOptions.GET, `/trends/${endpoint}`);

export const getTrendDates = (endpoint: string) =>
  callAPI(methodOptions.GET, `/trends/dates/${endpoint}/`);
